import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import marked from '@/utils/markedRenderer';
import ReactHTMLParser from 'react-html-parser';

import Section from '@latitude/section';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const APPLY_NOW_URL =
  'https://cards.latitudefinancial.com/gem';
const whyInterestFreeItems = [
  {
    icon: 'icon-flexible-branded',
    title: 'Shop with more time to pay',
    text:
      'Choose equal payments, minimum monthly payments, or pay when it suits you.'
  },
  {
    icon: 'icon-store-locator',
    title: 'Shop with choice',
    text:
      'Interest Free payment plans from 6 to 60 months available at hundreds of retailers.'
  },
  {
    icon: 'icon-cardless-branded',
    title: 'Stay in control',
    text:
      'Download the Latitude App to keep everything on track and use tap and pay on the go.'
  },
  {
    icon: 'icon-shopping-global',
    title: 'Pay off early, no worries',
    text:
      'Want to pay off your Interest Free payment plan early? Go for it! There are no penalties.'
  }
];

function WhyInterestFreeSection({ id, applyNowUrl = APPLY_NOW_URL }) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    featureSliderData: [{
      heading: "Why shop Interest Free?",
      description: "Use a participating Latitude credit card to access Interest Free payment plans.",
      featureCards: whyInterestFreeItems
    }]
  });

  useEffect(() => {
    let [featureSliders] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        default:
          break;
      }
    });

    setState({ 
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Section id={id}>
      {state?.featureSliderData?.[0] && (
        <FeaturesSlider
          data={state.featureSliderData[0].featureCards}
          heading={state.featureSliderData[0].heading}
          subheading={state.featureSliderData[0].description}
          css={{ padding: '30px 0' }}
        />
      )}
      <Link
        button={BUTTON_STYLE.SECONDARY}
        css={{ margin: '0 auto 0 auto', width: 'fit-content' }}
        target="_blank"
        rel="noopener noreferrer"
        href={applyNowUrl}
      >
        Get started
      </Link>
    </Section>
  );
}

export default WhyInterestFreeSection;
