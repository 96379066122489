import React, { useState } from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { SectionTitle } from './_sectionHeading';
import { BUTTON_STYLE, FONT_FAMILY } from '@latitude/core/utils/constants';

import iconShoppingCart from './images/icon-shopping-cart.svg';
import iconCreditCard from './images/icon-credit-card.svg';
import iconStopWatch from './images/icon-stopwatch.svg';
import iconSnapFingers from './images/icon-snap-fingers.svg';

const APPLY_NOW_URL =
  'https://cards.latitudefinancial.com/gem';

function contentNewUser(applyNowUrl) {
  return (
    <div>
      <div className="getstarted-steps">
        <div className="getstarted-step">
          <div className="getstarted-step-icon">
            <img src={iconShoppingCart} alt="Shopping cart" />
          </div>
          <p>
            <strong>Go shopping</strong>
          </p>
          <p>
            Browse in-store or online, until you find what you're looking for.
          </p>
        </div>
        <div className="getstarted-step">
          <div className="getstarted-step-icon">
            <img src={iconCreditCard} alt="credit card" />
          </div>
          <p>
            <strong>Sign up to Latitude</strong>
          </p>
          <p>
            <Link
              href={applyNowUrl}
              trackEventData={{
                location: 'getting-started',
                label: 'Apply for a Latitude credit card'
              }}
            >
              Apply for a Latitude credit card
            </Link>{' '}
            with our easy to use digital portal.
          </p>
        </div>
        <div className="getstarted-step">
          <div className="getstarted-step-icon">
            <img src={iconStopWatch} alt="Stopwatch" />
          </div>
          <p>
            <strong>Quick approval</strong>
          </p>
          <p>
            After you submit your application, we'll get back to you in 60sec
            flat.
          </p>
        </div>
        <div className="getstarted-step">
          <div className="getstarted-step-icon">
            <img src={iconSnapFingers} alt="Snap fingers" />
          </div>
          <p>
            <strong>Checkout time</strong>
          </p>
          <p>
            Choose your preferred payment plan at check out and you're all done!
          </p>
        </div>
      </div>
      <div className="apply-now">
        <Link
          button={BUTTON_STYLE.TERTIARY}
          href={applyNowUrl}
          trackEventData={{
            location: 'getting-started',
            label: 'Apply Now'
          }}
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
}

const contentExistingUser = (
  <div className="getstarted-steps">
    <div className="getstarted-step">
      <div className="getstarted-step-icon">
        <img src={iconCreditCard} alt="credit card" />
      </div>
      <p>
        <strong>Bring your credit card</strong>
      </p>
      <p>As a Latitude customer you can add purchases to a new payment plan.</p>
    </div>
    <div className="getstarted-step">
      <div className="getstarted-step-icon">
        <img src={iconShoppingCart} alt="Shopping cart" />
      </div>
      <p>
        <strong>Go shopping</strong>
      </p>
      <p>Browse in-store or online until you find what you're looking for.</p>
    </div>
    <div className="getstarted-step">
      <div className="getstarted-step-icon">
        <img src={iconSnapFingers} alt="Snap fingers" />
      </div>
      <p>
        <strong>Checkout time</strong>
      </p>
      <p>
        Choose your preferred payment plan at check out and you're all done!
      </p>
    </div>
  </div>
);

function GetStartedSection({ id, applyNowUrl = APPLY_NOW_URL }) {
  const [isExisting, setIsExisting] = useState(false);

  function onClickNewCustomer() {
    setIsExisting(false);
  }

  function onClickExistingCustomer() {
    setIsExisting(true);
  }

  return (
    <GetStartedContainer
      id={id}
      className={isExisting ? 'getstarted-existing' : 'getstarted-new'}
    >
      <SectionTitle>Get started</SectionTitle>
      <br />
      <div className="getstarted-select">
        <div className="getstarted-select-new" onClick={onClickNewCustomer}>
          For new customers
        </div>
        <div
          className="getstarted-select-existing"
          onClick={onClickExistingCustomer}
        >
          For existing customers
        </div>
      </div>

      <div className="getstarted-content">
        {isExisting ? contentExistingUser : contentNewUser(applyNowUrl)}
      </div>
    </GetStartedContainer>
  );
}

const GetStartedContainer = styled(Section)`
  & {
    padding: 64px 0;
  }

  &.getstarted-new {
    background-color: #e2f2ff;
  }
  &.getstarted-existing {
    background-color: #f1edff;
  }

  h2 {
    color: #000000;
  }

  div.getstarted-select {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 300;
    gap: 10pt;
    justify-content: center;
    line-height: 28px;
    margin: 0 auto 5pt auto;
  }
  div.getstarted-select div.getstarted-select-new,
  div.getstarted-select div.getstarted-select-existing {
    padding: 5pt 10pt;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  &.getstarted-new div.getstarted-select div.getstarted-select-new {
    font-weight: bold;
    cursor: default;
    border: 3px solid #7ec5ff;
  }
  &.getstarted-existing div.getstarted-select div.getstarted-select-existing {
    font-weight: bold;
    cursor: default;
    border: 3px solid #dfd7ff;
  }

  div.getstarted-content {
    margin: 30px 0 0 0;
  }

  div.getstarted-steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  div.getstarted-steps div.getstarted-step {
    flex-basis: 250px;
    font-face: 
    font-family: ${FONT_FAMILY.BODY};
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin: 0 10pt 0 0;
    text-align: center;
  }
  div.getstarted-steps div.getstarted-step strong {
    font-family: ${FONT_FAMILY.TITLE};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  div.getstarted-steps div.getstarted-step div.getstarted-step-icon {
    background-color: #ffffff;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 0 auto 0 auto;
  }
  div.getstarted-steps div.getstarted-step div.getstarted-step-icon img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: 10px;
  }
  div.apply-now a {
    width: fit-content;
    margin: 5pt auto;
  }
`;

export default GetStartedSection;
