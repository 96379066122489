import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Heading3 } from '@latitude/heading';
import { FONT_FAMILY } from '@latitude/core/utils/constants';
import {
  PLAN_TYPE_EQUAL_MONTHLY,
  PLAN_TYPE_FLEXIBLE_MONTHLY,
  PLAN_TYPE_MINIMUM_MONTHLY,
  PlanTypes
} from '@latitude/interest-free-plans';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

function HowPlansWorkSection({ id }) {
  return (
    <HowPlansWorkContainer id={id}>
      <SectionTitle>How Interest Free payment plans work</SectionTitle>
      <SectionSubtitle>
        Latitude offers different payment plans for you to access at thousands
        of retailers.
      </SectionSubtitle>

      <PlanTypes
        heading=""
        types={[
          PLAN_TYPE_EQUAL_MONTHLY,
          PLAN_TYPE_MINIMUM_MONTHLY,
          PLAN_TYPE_FLEXIBLE_MONTHLY
        ]}
      />
      <p css={{ textAlign: 'center', margin: '0 0', fontSize: '110%' }}>
        If you have a balance remaining at the end of your chosen Interest Free
        payment plan period, interest will apply.
      </p>
    </HowPlansWorkContainer>
  );
}

const HowPlansWorkContainer = styled(Section)`
  div.lfs-box-section {
    padding: 0 0;
  }
  div.lfs-box-section div.lfs-plan-type-detail {
    // border: 1px solid red;
  }
  div.lfs-box-section div.lfs-plan-type-details {
    flex-direction: row;
    align-content: stretch;
  }
  div.lfs-box-section div.lfs-plan-type-details div.lfs-plan-type-banner {
    height: 80%;
  }
`;

export default HowPlansWorkSection;
