import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import Slider from '@latitude/slider';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { SectionTitle } from './_sectionHeading';

import INTEREST_FREE_RETAILERS from './data/interest-free-merchants.json';
const MAX_RETAILERS = 6;

function ShopByRetailerSection({ id }) {
  const responsiveSettings = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    }
  ];
  function filterByCount(retailer, index, allRetailers) {
    return index < MAX_RETAILERS;
  }

  function retailerOrder(a, b) {
    // Compare by numerical order
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    // Compare name alphabetically (case-insensitive)
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    // Otherwise, they're equal
    return 0;
  }

  const retailers = INTEREST_FREE_RETAILERS.sort(retailerOrder).filter(
    filterByCount
  );

  return (
    <ShopByRetailerContainer id={id}>
      <SectionTitle>Shop by retailer</SectionTitle>

      <Link
        button={BUTTON_STYLE.TERTIARY}
        css={{ margin: '40px auto 0 auto', width: 'fit-content' }}
        href="./find-a-store/"
      >
        See all retailers
        <SvgInline name="chevron-right" />
      </Link>

      <div
        css={{
          padding: '5pt',
          margin: '40px auto'
        }}
      >
        <Slider
          className="lfs-carousel-retailers"
          responsiveSettings={responsiveSettings}
        >
          {retailers.map((retailer, i) => (
            <div
              key={i}
              className="lfs-carousel-retailer"
              css={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #eeeeee',
                borderRadius: '4px',
                boxShadow: '0px 2px 5px 0px #0000001A',
                padding: '20pt 10pt',
                textAlign: 'center'
              }}
            >
              <div className="img">
                <img
                  src={retailer.logo}
                  alt={retailer.name}
                  css={{
                    height: '100px',
                    width: '130px',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <Link
        button={BUTTON_STYLE.SECONDARY}
        css={{ margin: '0 auto 0 auto', width: 'fit-content' }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://cards.latitudefinancial.com/gem"
      >
        Apply Now
      </Link>
    </ShopByRetailerContainer>
  );
}

const ShopByRetailerContainer = styled(Section)`
  background-color: #f8f8f8;
  padding: 64px 0;
`;

export default ShopByRetailerSection;
