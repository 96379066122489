import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import Slider from '@latitude/slider';
import { Heading3 } from '@latitude/heading';
import { BUTTON_STYLE, FONT_FAMILY } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';

import imgConfettiLeft from './images/offers-confetti-left.svg';
import imgConfettiRight from './images/offers-confetti-right.svg';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

import INTEREST_FREE_OFFERS from './data/interest-free-offers.json';

const MAX_OFFERS = 4;

function InterestFreeOffersSection({ id }) {
  const responsiveSettings = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: false
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    }
  ];

  function filterByCount(offers, index, allOffers) {
    return index < MAX_OFFERS;
  }

  function offerMerchantOrder(a, b) {
    // Compare merchants by numerical order
    if (a.merchant.order > b.merchant.order) return 1;
    if (a.merchant.order < b.merchant.order) return -1;
    // Compare merchant names alphabetically
    if (a.merchant.name > b.merchant.name) return 1;
    if (a.merchant.name < b.merchant.name) return -1;
    // Otherwise, they're equal
    return 0;
  }

  return (
    <InterestFreeOffersContainer id={id}>
      <SectionTitle>Our latest Interest Free offers</SectionTitle>
      <SectionSubtitle>
        Access Interest Free payment plans with participating Latitude credit
        cards.
      </SectionSubtitle>

      <Link
        href="./find-an-offer/"
        button={BUTTON_STYLE.SECONDARY}
        css={{
          margin: '30px auto 0 auto',
          width: 'fit-content',
          border: '1px solid #fff !important'
        }}
      >
        See all offers
        <SvgInline name="chevron-right" />
      </Link>
      <br />
      <br />
      <Slider
        className="lfs-carousel-offers"
        responsiveSettings={responsiveSettings}
      >
        {INTEREST_FREE_OFFERS.sort(offerMerchantOrder)
          .filter(filterByCount)
          .map((offer, i) => (
            <OfferItem key={i}>
              <div>
                <img
                  src={offer.merchant.logo}
                  alt={offer.merchant.name}
                  loading="lazy"
                />
              </div>
              <Link
                href={offer.link}
                button={BUTTON_STYLE.SECONDARY}
                css={{
                  margin: '10pt auto',
                  width: 'fit-content',
                  fontSize: '13px',
                  lineHeight: '20px'
                }}
              >
                {offer.merchant.linkTitle
                  ? offer.merchant.linkTitle
                  : 'View Offer'}
                &nbsp;
                <SvgInline name="external-link" />
              </Link>
            </OfferItem>
          ))}
      </Slider>

      <div
        css={{
          textAlign: 'center',
          width: '100%'
        }}
      >
        <p>T&amp;Cs, fees, charges and exclusions apply.</p>
        <br />
        <Link
          button={BUTTON_STYLE.SECONDARY_INVERSE}
          css={{ margin: '10pt auto 5pt auto', width: 'fit-content' }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://cards.latitudefinancial.com/gem"
        >
          Apply Now
        </Link>
      </div>
    </InterestFreeOffersContainer>
  );
}

const InterestFreeOffersContainer = styled(Section)`
  background-color: #0146aa;
  background-image: linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%);
  @media only screen and (min-width: 768px) {
    background: url(${imgConfettiLeft}) no-repeat left top,
      url(${imgConfettiRight}) no-repeat right top,
      linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%);
    background-size: auto 100%, auto, auto 100%;
  }
  padding: 64px 0;

  h3,
  p {
    color: #ffffff;
    text-align: center;
  }
`;

const OfferItem = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #777;
  padding: 15pt;
  text-align: center;

  img {
    height: 80px;
    margin: 0 auto;
    object-fit: contain;
    padding: 0 0;
    width: 150px;
  }
`;

export default InterestFreeOffersSection;
