import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import Slider from '@latitude/slider';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

import { SectionTitle } from './_sectionHeading';
import CATEGORIES from './data/interest-free-categories.json';

function ShopByCategorySection({ id }) {
  const responsiveSettings = [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true
      }
    }
  ];

  return (
    <ShopByCategoryContainer id={id}>
      <SectionTitle>Shop by retail category</SectionTitle>

      <Link
        button={BUTTON_STYLE.TERTIARY}
        css={{ margin: '40px auto 0 auto', width: 'fit-content' }}
        href="./find-by-category/"
      >
        See all categories
        <SvgInline name="chevron-right" />
      </Link>

      <div className="category-items">
        <Slider
          className="lfs-carousel-categories"
          responsiveSettings={responsiveSettings}
        >
          {CATEGORIES.map((category, i) => (
            <div className="category-item" key={i}>
              <a
                href={`./find-by-category/?c=${encodeURIComponent(
                  category.name
                )}`}
                className="category-item"
              >
                <div className="img">
                  <img src={category.image} alt={category.name} />
                </div>
                <div className="text">{category.name}</div>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <Link
        button={BUTTON_STYLE.SECONDARY}
        css={{ margin: '0 auto 0 auto', width: 'fit-content' }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://cards.latitudefinancial.com/gem"
      >
        Apply Now
      </Link>
    </ShopByCategoryContainer>
  );
}

const ShopByCategoryContainer = styled(Section)`
  background-color: #ffffff;
  padding: 64px 0;

  div.category-items {
    margin: 40pt auto;
  }
  div.category-items div.category-item {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 5px 0px #0000001a;
    color: #000000;
    margin: 5px 0;
    padding: 0;
  }
  div.category-items a.category-item {
    cursor: pointer;
    text-decoration: none;
  }
  div.category-items div.category-item:hover {
    background-color: #e2f2ff;
  }

  div.category-items a.category-item div.img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  div.category-items a.category-item div.text {
    color: #000;
    font-size: 18px;
    height: 60px;
    padding: 2pt;
    text-align: center;
  }
`;

export default ShopByCategorySection;
