import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import HowToApply from '@latitude/how-to-apply';
import { Link } from '@latitude/link';
import { Heading4 } from '@latitude/heading';
import { ListNumbered } from '@latitude/list-numbered';
import { List, ListItem } from '@latitude/list';
import { BUTTON_STYLE, COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Vertical } from '@latitude/spacing';
import { Bold } from '@latitude/text';

import { SectionTitle } from './_sectionHeading';

const APPLY_NOW_URL =
  'https://cards.latitudefinancial.com/gem';

function content1(applyNowUrl) {
  return (
    <>
      <Heading4 marginBottom={MARGIN.M16} isResponsive={false}>
        Let&rsquo;s do this.
      </Heading4>
      <ListNumbered
        data={[
          "<div><strong>Apply online</strong><br />You can check your eligibility and <a target='_blank' href='" +
            applyNowUrl +
            "'>apply in 10 minutes</a>.</div>",
          '<div><strong>Wait a minute</strong><br />60 seconds is all it takes for us to respond to your application.</div>',
          "<div><strong>Ready, set, shop</strong><br />Set up your card in your <a href='/digital-wallets/apple-pay/'>digital wallet</a> and start shopping today!</div>"
        ]}
        separator="none"
        counterBgColor={COLOR.BLUE_DEEP}
      />
    </>
  );
}

const content2 = (
  <Vertical spacing={MARGIN.M16}>
    <Heading4 color={COLOR.BLACK} isResponsive={false}>
      Eligibility
    </Heading4>
    <Vertical spacing={MARGIN.M8}>
      <Bold>You must be:</Bold>
      <List>
        <ListItem>Over 18 years of age</ListItem>
        <ListItem>
          A resident of Australia or a temporary resident who legally has the
          right to work in Australia (and intend to stay) for at least the next
          12 months.
        </ListItem>
      </List>
    </Vertical>
    <Vertical spacing={MARGIN.M8}>
      <Bold>Able to supply:</Bold>
      <List>
        <ListItem>Your driver's licence, passport or Medicare card</ListItem>
        <ListItem>Details of your income, living expenses & debts</ListItem>
      </List>
    </Vertical>
  </Vertical>
);

function HowToApplySection({ id, applyNowUrl = APPLY_NOW_URL }) {
  return (
    <HowToApplyContainer id={id}>
      <SectionTitle>
        How to apply for a Latitude Gem Visa credit card
      </SectionTitle>

      <HowToApply box1={content1(applyNowUrl)} box2={content2} />
      <div
        css={{
          textAlign: 'center',
          width: '100%'
        }}
      >
        <Link
          button={BUTTON_STYLE.SECONDARY}
          css={{ margin: '10pt auto 5pt auto', width: 'fit-content' }}
          target="_blank"
          rel="noopener noreferrer"
          href={applyNowUrl}
        >
          Apply Now
        </Link>
      </div>
    </HowToApplyContainer>
  );
}

const HowToApplyContainer = styled(Section)`
  padding: 64px 0;
`;

export default HowToApplySection;
